/* *{
  cursor: url(../public/images/tattoo.cur) 0 30, auto;
} */


*{
font-family: 'Antic Didone', serif;
}

body {
  margin: 0;
font-family: 'Space Grotesk', sans-serif;
}


.section {
  margin: 80px;
}

@media (max-width: 767px) {
  .section {
    margin: 30px;
  }
}
