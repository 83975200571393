html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #252527;
}

body {
  font-family: "Helvetica Neue", sans-serif;
  font-weight: lighter;
}

.wrapper header {
  width: 100%;
  height: 100vh;
  position: relative;
  align-items: center;
}

.wrapper header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../../public/images/bg6.jpg") no-repeat 50% 50%;
  background-size: cover;
  filter: grayscale(100%);
}

.follow-us-container {
  position: fixed;
  bottom: 180px;
  /* transform: translateY(-50%); */
}

.consult-container {
  display: inline-flex;
  bottom: 15vh;
  position: absolute;
  flex-wrap: wrap;
  width: 422px;
  justify-content: space-between;
}

.consult {
  width: 170px;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #a68954;
  /* backdrop-filter: blur(15.5px); */
}

.consult-button1, .consult-button2 {
  display: flex;
  position: relative;
  font-weight: 2em;
  align-items: center;
  padding: auto 4px;
  background-color: transparent;
  width: 170px;
  height: 50px;
  /* box-shadow: 0 8px 10px 0 rgb(37, 37, 39); */
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  backdrop-filter: blur(15.5px);
  -webkit-backdrop-filter: blur(15.5px);
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: all 0.3s;
}

.button-text-first {
  position: absolute;
  left: 28px;
}

.button-text {
  position: absolute;
  left: 10px;
}

.gs-icon {
  background: #a68954;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 2em;
  right: 4px;
  transition: all 0.3s;
}

.consult-button1:hover .gs-icon {
  width: calc(100% - 0.6em);
}
.consult-button2:hover .gs-icon {
  width: calc(100% - 0.6em);
}

.content {
  margin: auto;
  width: 85%;
  margin: 4em auto;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  text-align: justify;
}

p,
h2 {
  text-align: center;
  color: white;
}

.logo {
  line-height: 50px;
  position: absolute;
  float: left;
  margin: 0 30px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 2px;
  transition: all 0.7s;
}

tav {
  position: fixed;
  z-index: 2;
  width: 100%;
  line-height: 50px;
}

tav ul {
  line-height: 50px;
  list-style: none;
  background: rgba(0, 0, 0, 0);
  overflow: hidden;
  color: #fff;
  padding: 0;
  text-align: right;
  margin: 0;
  padding-right: 30px;
  transition: 0.7s;
}

tav ul li {
  display: inline-block;
  padding: 16px 40px;
}

/* to animate navbar links, as line through */
.nav-link {
  text-decoration: none;
  position: relative;
  color: #fff;
  font-size: 16px;
  transition: all 2s ease;
}

.nav-link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 3px;
  background-color: #a68954;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.nav-link:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.menu-icon {
  line-height: 50px;
  width: 100%;
  text-align: right;
  box-sizing: border-box;
  padding: 15px 24px;
  cursor: pointer;
  color: #fff;
  display: none;
}

.brand-impression {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
}

#landing-text1,
#landing-text2 {
  margin: 0;
  color: whitesmoke;
  align-items: center;
  font-size: 7vw;
  display: block;
  letter-spacing: 10px;
  text-shadow: 3px 3px 20px whitesmoke, -2px 1px 30px whitesmoke;
  transition: all 0.7s;
}

.e-text {
  position: relative;
  z-index: 1;
}

.est-container {
  position: absolute;
  transform: translate(-60%, -35%);
  -webkit-transform: translate(-60%, -35%);
  display: inline;
  z-index: -1;
}

.est {
  width: 130px;
  height: 130px;
  transform-origin: center calc(-50%, -50%);
  animation: rotate 9s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

#landing-text1 span {
  opacity: 0;
  animation: fade-in 1s ease-in-out forwards;
}
#landing-text2 span {
  opacity: 0;
  animation: fade-in 1s ease-in-out forwards;
}

.consult-button1, .consult-button2 {
  opacity: 0;
  animation: fade-in 1s ease-in-out forwards;
}


@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#landing-text1 span:nth-child(1) {
  animation-delay: 1.3s;
}

#landing-text1 span:nth-child(2) {
  animation-delay: 1.6s;
}

#landing-text1 span:nth-child(3) {
  animation-delay: 1.9s;
}
#landing-text1 span:nth-child(4) {
  animation-delay: 2.2s;
}
#landing-text1 span:nth-child(5) {
  animation-delay: 2.5s;
}
#landing-text2 span:nth-child(1) {
  animation-delay: 2.8s;
}
#landing-text2 span:nth-child(2) {
  animation-delay: 3.1s;
}
#landing-text2 span:nth-child(3) {
  animation-delay: 3.4s;
}
#landing-text2 span:nth-child(4) {
  animation-delay: 3.7s;
}
#landing-text2 span:nth-child(5) {
  animation-delay: 4s;
}
#landing-text2 span:nth-child(6) {
  animation-delay: 4.3s;
}
#landing-text2 span:nth-child(7) {
  animation-delay: 4.6s;
}

.consult-button1{
  animation-delay: 4.9s;
}
.consult-button2{
  animation-delay: 5.2s;
}

@media (max-width: 764px) {
  header {
    height: 94vh;
  }

  .follow-us-container {
    bottom: 260px;
    z-index: 1;
  }

  .logo {
    position: absolute;
    top: 0;
    margin-top: 16px;
  }

  tav ul {
    max-height: 0px;
    box-shadow: 0 8px 32px 0 rgb(37, 37, 39);
    backdrop-filter: blur(15.5px);
    -webkit-backdrop-filter: blur(15.5px);
  }

  tav.black ul {
    box-shadow: 0 8px 32px 0 rgb(37, 37, 39);
    backdrop-filter: blur(15.5px);
    -webkit-backdrop-filter: blur(15.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
  }

  .showing {
    max-height: 34em;
  }

  tav ul li {
    box-sizing: border-box;
    width: 100%;
    padding: 24px;
    text-align: center;
  }

  .menu-icon {
    display: block;
  }

  #landing-text1,
  #landing-text2 {
    letter-spacing: 5px;
    font-size: 14vw;
  }

  #landing-text3 {
    font-size: 1.8vw;
    letter-spacing: 2px;
  }

  .est {
    width: 90px;
    height: 90px;
  }

  .brand-impression {
    position: relative;
    bottom: 50px;
  }

  .consult-container {
    width: 300px;
    justify-content: center;
    bottom: 18vh;
  }

  .consult-button2{
    position: relative;
    left: 0;
    top: 20px;
  }
}

/* hidden and show classes flying text */
.fly-hidden {
  opacity: 0;
  transform: translateY(40%);
  transition: all 0.7s;
}

.fly-show {
  opacity: 1;
  transform: translateY(0);
}

.glass-effect {
  box-shadow: 0 8px 32px 0 rgb(37, 37, 39);
  -webkit-box-shadow: 0 8px 32px 0 rgb(37, 37, 39);
  backdrop-filter: blur(15.5px);
  -webkit-backdrop-filter: blur(15.5px);
  transition: all 0.3s;
}
