.counter {
  margin: 80px 0;
  transition: all 0.5s;
}

.cwrapper {
  position: relative;
  width: 80vw;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.ccontainer {
  width: 225px;
  height: auto;
  margin-top: 10px;
  border: 1px solid #a68954;
  display: flex;
  flex-direction: column;
  padding: 1em 0;
  font-size: 16px;
  border-radius: 0.5em;
  border-bottom: 10px solid #a68954;
  transition: all 0.7s;
}

.ccontainer:hover {
  transform: scale(1.2);
  z-index: 1;
}

.ci {
  color: #a68954;
  display: block;
  text-align: center;
  align-items: center;
  margin: auto;
}

.horizontal-row {
  color: white;
  margin: 50px 10% 0px;
}

span {
  color: white;
}

span.cnum {
  color: #fff;
  font-weight: 600;
  font-size: 3em;
}

span.xnum {
  color: #fff;
  /* display: grid; */
  align-items: center;
  place-items: center;
  font-weight: 600;
  font-size: 3em;
}

span.ccnum {
  color: #fff;
  display: grid;
  align-items: center;
  place-items: center;
  font-weight: 600;
  font-size: 3em;
}

span.ctext {
  color: #e0e0e0;
  font-size: 1em;
  text-align: center;
  pad: 0.7em 0.5em;
  padding: 10px;
  font-weight: 400;
  line-height: 0;
}

.line-pass {
  display: inline-block;
  padding-bottom: 15px;
  margin-bottom: 15px;
  text-decoration: none;
  position: relative;
  color: #fff;
  transition: all 2s ease;
}

.line-pass::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #a68954;
  bottom: 0;
  left: 0;
}

@media (max-width: 767px) {
  .counter {
    margin: 40px;
  }
}
