.gswiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.gswiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    filter:grayscale(100%);
    transition:all 0.3s;
    object-fit: cover;
}

.front-slide img{
   filter: grayscale(0);
}