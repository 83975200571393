.full-screen-logo{
      display: inline-block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vh;
      height: 100vh;
      opacity: 1;
      transition: opacity 0.5s ease-out;
}











