.whychooseus-section {
    margin: 0px;
    background-color: #A68954;
    padding-bottom: 80px;
}

.whychooseus-section h2{
    padding-top: 80px;
}

.whychooseus-line-pass {
    display: inline-block;
    padding-bottom: 15px;
    margin-bottom: 15px;
    text-decoration: none;
    position: relative;
    color: #fff;
    transition: all 2s ease;
}

.whychooseus-line-pass::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: black;
    bottom: 0;
    left: 0;
    /* transform-origin: right; */
    /* transform: scaleX(0);
    transition: transform .3s ease-in-out; */
}

.whychooseus {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
}

.whychooseus-heading {
    color: white;
    margin: 18px auto;
}

.whychooseus-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    margin: 30px 30px;
}

.whychooseus-icon img {
    width: 50px;
    height: 50px;
}
