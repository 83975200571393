.sectionA{
    display: flex;
}

.sectionA img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: grayscale(100%) blur(0.7px);
    transition: filter 3s ease;
}

.sectionA img:hover{
    filter: brightness(100%);
}

