.cta {
  display: inline-block;
  position: fixed;
  z-index: 1;
  bottom: 1%;
  right: 15px;
  opacity: 1;
}

.ctaIcon {
  padding: 4px 4px;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 7px;
  box-shadow: 0 8px 32px 0 rgb(37, 37, 39);
  backdrop-filter: blur(15.5px);
  -webkit-backdrop-filter: blur(15.5px);
  border-radius: 100%;
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin-top: 5px;
  transition: all 0.7s ease;
  text-decoration: none;
}

.ctaPhone:hover {
  box-shadow: 0 0 30px 10px #a68954;
  transition: all 0.7s ease;
}

.ctaPhoneLink:hover {
  color: #a68954;
  transition: all 0.7s ease;
}

.ctaWhatsapp:hover {
  /* border: 5px solid #A68954; */
  box-shadow: 0 0 30px 10px #a68954;
  transition: all 0.7s ease;
}

.ctaWhatsappLink:hover {
  color: #a68954;
  transition: all 0.7s ease;
}

.ctaInstagram:hover {
  /* border: 5px solid #A68954; */
  box-shadow: 0 0 30px 10px #a68954;
  transition: all 0.7s ease;
}

.ctaInstagramLink:hover {
  color: #a68954;
  transition: all 0.7s ease;
}

.ctaToggle:hover {
  /* border: 5px solid #000; */
  box-shadow: 0 0 30px #000;
  transition: all 0.5s ease;
}

.cta-hidden {
  opacity: 0;
  transition: all 0.7s ease;
}

.non-display {
  display: none;
}
