.gallery-header {
  width: 100%;
  position: relative;
  height: 0px;
  align-items: center;
}

.gallery-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
