header{
    position: fixed;
}

.visiting-card-screen {
  background: linear-gradient(-45deg, #b84c2b, #c62f69, #187da2, #20a761);
  background-size: 400% 400%;
  animation: gradient 12s ease infinite;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "PT Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: large;
  color: white;
  overscroll-behavior: none;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.visiting-card-container {
  width: 80vw;
  height: 75vh;
  border: 0.5px solid;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.67);
}

.visiting-card-box {
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 30px;

  backdrop-filter: blur(100px) saturate(180%);
  -webkit-backdrop-filter: blur(100px) saturate(180%);
  border-radius: 12px;
}

.visiting-card-section {
  width: 80%;
  margin: auto 5% auto;
}

.visitingcardtoggleDown {
  cursor: pointer;
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}

.visitingcardtoggleDown.rotate {
  transform: rotate(180deg);
}

.visiting-card-heading {
  width: 20%;
  margin: auto;
  text-align: center;
  font-family: cursive;
  padding: 1rem 0;
}

.toggling-content {
  height: 0;
  transform: scaleY(0);
  transform-origin: top;
  overflow: hidden;
  transition: height 0.5s ease-in-out, transform 0.5s ease-in-out;
  position: relative;
  left: 20px;
}

.toggling-content.show1 {
  height: 80px;
  transform: scaleY(1);
}

.toggling-content.show2 {
  height: 125px;
  transform: scaleY(1);
}

.visiting-card-icon {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.visiting-card-icon > div {
  margin: auto 6px;
  padding: 4px;
}

.vc-link > a {
  text-decoration: dashed;
  color: white;
  margin: 8px auto;
}

.show-different {
  transform: rotate(-90deg);
}

.vc-single-link {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  margin: auto 2px;
}