.footer-container {
  /* padding: 0; */
  display: flex;
  background: #333;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 80px;
}

.footer {
  position: relative;
  width: 100%;
  background: #a68954;
  min-height: 100px;
  @media (max-width: 764px) {
    padding-right: 20px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  transition: all 1s;
}

.footer p {
  display: block;
  @media (min-width: 764px) {
    padding: auto 55px;
  }
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
}

.fp {
  padding: auto 50px;
}

.modal-footer button {
  background-color: #a68954;
  outline-color: #a68954;
  border-color: #a68954;
}

.modal-footer button:hover {
  background-color: #a68954;
}

.wave {
  position: absolute;
  top: -98px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url("../../public/images/wave.svg");
  transform: rotate(180deg);
  transition: all 1s;
  background-size: 1000px 100px;
}

.wave#wave1 {
  opacity: 1;
  bottom: 0;
  animation: animateWaves 5s linear infinite;
}

.wave#wave2 {
  opacity: 0.5;
  /* bottom: 10px; */
  animation: animate 4s linear infinite !important;
}

.wave#wave3 {
  opacity: 0.2;
  /* bottom: 15px; */
  animation: animateWaves 6s linear infinite;
}

.wave#wave4 {
  opacity: 0.7;
  /* bottom: 20px; */
  animation: animate 7s linear infinite;
}

@keyframes animateWaves {
  0% {
    background-position-x: 1000px;
  }

  100% {
    background-positon-x: -1000px;
  }
}

@keyframes animate {
  0% {
    background-position-x: -1000px;
  }

  100% {
    background-positon-x: 1000px;
  }
}
