.eye1,
.eye2 {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background: #ccc;
}

.eye1:after,
.eye2:after {
  /*pupil*/
  position: absolute;
  bottom: 17px;
  right: 10px;
  width: 10px;
  height: 10px;
  background: #a68954;
  border-radius: 50%;
  content: " ";
}
