.section {
  color: white;
  text-align: center;
  margin: auto;
}

.address {
  margin-bottom: 15px;
}

.address * {
  margin: 4px auto;
}

.details {
  display: block;
  text-align: center;
}

.details a {
  text-decoration: none;
  color: wheat;
}

.timing {
  line-height: 2rem;
}

.col-lg-4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.map {
  width: 650px;
  position: relative;
  z-index: 1;
  height: 350px;
  transition: all 0.7s;
  filter: invert(100%);
}

@media (max-width: 767px) {
  .map {
    width: 100%;
    height: 350px;
    text-align: center;
    align-items: center;
    margin: auto;
  }

  .row {
    margin: auto;
    text-align: center;
    align-items: center;
  }
  .col-lg-8 {
    padding: 0;
  }

  .cnt {
    display: block;
  }
}
