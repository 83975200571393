.brand{
    position: relative;
    width: 360px;
    justify-content: flex-start;
    transition: all 0.8 ease;
    z-index: 2000;
    /* animation: flicker-1 2.5s linear infinite both; */
}

@media(max-width: 892px){
    .brand{
        width: 230px;
        transform: translateX(-25px);
        transition: all 0.7 ease;
    }
}

#blogo{
    width: 10%;
    transition: all 0.7s ease;
    filter:contrast(500%);
}

#bname{
    width: 22%;
    transition: all 0.7s ease;
}

.bblur{
    border-radius: 10%;
}
