.ourwork-section{
    margin: 80px;
}

.ourwork{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
}

.work-heading{
    color:#fff;
    margin:18px auto;
}

.work-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:300px;
    margin:30px 30px;
}

.work-icon img{
    width: 50px;
    height: 50px;
}

#permanent-tattoo-icon{
    width: 60px;
    height: 60px;
}


@media (max-width: 767px) {
    .ourwork-section {
        margin: 30px;
    }
}