.form-section {
  display: flex;
  justify-content: center;
}

.form-section header {
  width: 100%;
  height: 100vh;
  position: relative;
  align-items: center;
  justify-content: center;
}

.form-section header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../../public/images/bg4.jpg") no-repeat 50% 50%;
  background-size: cover;
  /* height: 100vh; */
}

.form-container {
  margin: 120px auto 0px;
  position: absolute;
  width: 70vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px 20px 60px;
  border-radius: 20px;
}

.form-container * {
  font-weight: bolder;
}

.form-control {
  position: relative;
  margin: 20px 0;
  width: 300px;
  background-color: transparent;
  border: none;
}

.form-control input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #fff;
  padding: 15px 0;
  display: block;
  font-size: 18px;
  font-family: "Muli", sans-serif;
  width: 100%;
  transition: 0.1s ease-in;
  color: white;
}

.form-control input:focus,
.form-control input:valid {
  border-bottom-color: #a68954;
  outline: none;
}

.form-control input:focus + label span,
.form-control input:valid + label span {
  color: #a68954;
  transform: translateY(-30px);
}

.form-control label {
  position: absolute;
  top: 15px;
  left: 0;
}

.form-control label span {
  display: inline-block;
  font-size: 18px;
  min-width: 5px;
  transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

input:checked {
  accent-color: #a68954;
}

.form-submit-button {
  height: 45px;
  border-radius: 40px;
  color: white;
  width: 160px;
  background-color: #a68954;
  border: none;
  position: absolute;
  bottom: 18px;
}

.move-area {
  position: absolute;
  top: -15px;
}

#fnav {
  z-index: 10000;
}

.ToastContainer {
  color: #a68954;
}

#number-input::-webkit-inner-spin-button,
#number-input::-webkit-outer-spin-button {
  display: none;
}
